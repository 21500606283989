import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Button, Card, Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { LimbusCharacter } from '../../../modules/lc/common/components/lc-character';

const LimbusRerollPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="lc">
      <ul className="breadcrumb">
        <li>
          <Link to="/limbus-company/">Limbus Company</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/limbus-company/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Reroll</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/limbuscompany/categories/category_reroll.jpg"
            alt="Reroll"
          />
        </div>
        <div className="page-details">
          <h1>Reroll</h1>
          <h2>How to reroll in Limbus Company.</h2>
          <p>
            Last updated: <strong>13/05/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <p>
          Please keep in mind that rerolling on the Steam client isn't possible
          as the game doesn't have the Guest Account option. This means you have
          to reroll either on your mobile phone or an emulator - if you want to
          save time, rerolling on an emulator is quicker if your PC can handle
          multiple instances. We suggest using BlueStacks emulator as the game
          runs smoothly there and their multi-instance system is easy to use.
          You can download BlueStacks from our link and by doing so, support
          Prydwen:
        </p>
        <div className="banner-limbus sm">
          <div className="cta">
            <h4>Reroll and play Limbus Company on PC!</h4>
            <OutboundLink href="https://bstk.me/mGKpobpBu" target="_blank">
              <Button variant="primary">Play now for free</Button>
            </OutboundLink>
          </div>
        </div>
        <p>
          Also, if you want to reroll using multi-instances, make sure to clone
          the first instance BEFORE you launch the game - since launching the
          game for the first time saves your ID to it and when you clone it
          after you won't be able to have the game open at the same time on
          multiple instances.
        </p>
        <SectionHeader title="The process" />
        <p>
          Thankfully, the reroll is really fast, as you can basically skip the
          whole tutorial and start pulling - it takes like 2-3 minutes per
          reroll. On top of that, you don't need to worry about clearing cache
          or anything as the game has an in-built account reset system, but more
          about that later.
        </p>
        <p>
          <strong>Important</strong>! There's a bug currently that sometimes
          causes your Guest Account to wipe after a client crash. So we suggest
          binding your account as soon as you get something good.
        </p>
        <StaticImage
          src="../../../images/limbuscompany/generic/reroll_1.jpg"
          alt="Guide"
        />
        <p>
          Once you first launch the game, pick the Guest Account option. Also,
          keep in mind that the first time you're setting an instance or
          launching the game, you will have to download additional in-game patch
          that will vary depending if you are on phone or PC. Thankfully, you
          will only have to do it once per each instance.
        </p>
        <StaticImage
          src="../../../images/limbuscompany/generic/reroll_2.jpg"
          alt="Guide"
        />
        <p>
          Once your download ends, click on the SKIP button in top right corner.
          This will allow you to Skip the whole tutorial. Still, the game is
          really complicated so we suggest to play though it at least once.
        </p>
        <StaticImage
          src="../../../images/limbuscompany/generic/reroll_3.jpg"
          alt="Guide"
        />
        <p>
          After skipping the tutorial, you will end up in the menu. Here check
          your mail (top right corner) and grab currently available rewards:
        </p>
        <StaticImage
          src="../../../images/limbuscompany/generic/reroll_4.jpg"
          alt="Guide"
        />
        <p>
          Now head up to the Extract tab. There are usually 3 banners available
          for new players: Newbie banner, Limited banner, Standard banner. In
          the banners you will find both IDs (characters) and E.G.O.s (let's
          call them artifacts). E.G.O. pull is finite which means that after
          pulling those available on the banner, you won't be able to get them
          anymore. IDs use a different system, but if you want to learn more
          about them, check our other guide:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Gacha and monetization"
            link="/limbus-company/guides/gacha-and-monetization"
            image={
              <StaticImage
                src="../../../images/limbuscompany/categories/category_gacha.png"
                alt="Gacha and monetization"
              />
            }
          />
        </Row>
        <p>
          As for the rarities of IDs, there are three: 0, 00, 000 - where 000 is
          the equivalent of SSR from other games.
        </p>
        <p>The rates are as follows:</p>
        <ul>
          <li>EGO - 1.3%</li>
          <li>000 - 2.9%</li>
          <li>00 - 12.8%</li>
          <li>0 - 83%</li>
        </ul>
        <StaticImage
          src="../../../images/limbuscompany/generic/reroll_7.jpg"
          alt="Guide"
        />
        <p>
          If you're happy with who you've got, bind your account to prevent
          losing it (Settings &gt; Account). If you're not happy with your
          account, you can scroll down on the Account screen and at the bottom
          you will find Close Account button. This will reset the account and
          take you back to login screen.{' '}
        </p>
        <SectionHeader title="Who to reroll for?" />
        <StaticImage
          src="../../../images/limbuscompany/generic/names.jpg"
          alt="Guide"
        />
        <h5>Very High Priority</h5>
        <p>
          Only available on Walpurgis Night which is an event that occurs every
          three months and lasts for two weeks. If you have the opportunity try
          to reroll for him or save shards to pick him up from the dispenser on
          the next Walpurgis Night as he is a LIMITED high value unit.
        </p>
        <div className="employees-container-card-mode-limbus">
          <Card className="avatar-card">
            <LimbusCharacter
              slug="dawn-office-fixer-sinclair"
              mode="card"
              enablePopover
              showLabel
            />
          </Card>
        </div>
        <h5>High Priority</h5>
        <p>
          Very strong even in early game that will get much better as you
          progress. Butler Ryoshu in particular helps speed up MD farming.
        </p>
        <div className="employees-container-card-mode-limbus">
          <Card className="avatar-card">
            <LimbusCharacter
              slug="r-corp-4th-pack-rabbit-heathcliff"
              mode="card"
              enablePopover
              showLabel
            />
          </Card>
          <Card className="avatar-card">
            <LimbusCharacter
              slug="the-one-who-shall-grip-sinclair"
              mode="card"
              enablePopover
              showLabel
            />
          </Card>
          <Card className="avatar-card">
            <LimbusCharacter
              slug="cinq-south-section-4-director-sinclair"
              mode="card"
              enablePopover
              showLabel
            />
          </Card>
          <Card className="avatar-card">
            <LimbusCharacter
              slug="edgar-family-chief-butler-ryoshu"
              mode="card"
              enablePopover
              showLabel
            />
          </Card>
        </div>
        <h5>Medium Priority</h5>
        <p>Not as good but still quite strong and offer a lot of utility.</p>
        <div className="employees-container-card-mode-limbus">
          <Card className="avatar-card">
            <LimbusCharacter
              slug="k-corp-class-3-excision-staff-hong-lu"
              mode="card"
              enablePopover
              showLabel
            />
          </Card>
          <Card className="avatar-card">
            <LimbusCharacter
              slug="tingtang-gang-gangleader-hong-lu"
              mode="card"
              enablePopover
              showLabel
            />
          </Card>
          <Card className="avatar-card">
            <LimbusCharacter
              slug="dieci-south-section-4-rodion"
              mode="card"
              enablePopover
              showLabel
            />
          </Card>
          <Card className="avatar-card">
            <LimbusCharacter
              slug="effloresced-e-g-o-spicebush-yi-sang"
              mode="card"
              enablePopover
              showLabel
            />
          </Card>
        </div>
        <h5>00 Identities</h5>
        <p>And here are some great 00 rarity Identities.</p>
        <div className="employees-container-card-mode-limbus">
          <Card className="avatar-card">
            <LimbusCharacter
              slug="lobotomy-corp-remnant-faust"
              mode="card"
              enablePopover
              showLabel
            />
          </Card>
          <Card className="avatar-card">
            <LimbusCharacter
              slug="seven-association-south-section-6-ryoshu"
              mode="card"
              enablePopover
              showLabel
            />
          </Card>
          <Card className="avatar-card">
            <LimbusCharacter
              slug="lccb-assistant-manager-ishmael"
              mode="card"
              enablePopover
              showLabel
            />
          </Card>
        </div>
        <h5>E.G.O.</h5>
        <p>
          As far as E.G.O. comes, those are less important than Identities and
          you can clear the whole game relying mostly on base E.G.O. every
          sinner starts with. There is little reason to reroll purely for E.G.O.
        </p>
        <p>You can also check our tier list for more information:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Tier List"
            link="/limbus-company/tier-list"
            image={
              <StaticImage
                src="../../../images/limbuscompany/categories/category_4.jpg"
                alt="Tier list"
              />
            }
          />
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
    </DashboardLayout>
  );
};

export default LimbusRerollPage;

export const Head: React.FC = () => (
  <Seo
    title="Reroll | Limbus Company | Prydwen Institute"
    description="How to reroll in Limbus Company."
    game="limbus"
  />
);
